import { createReducer } from "@reduxjs/toolkit";
import { clearCanvas } from "../actions/global";
import { setLock, setVisiblity } from "../actions/layers";
import {
  LAYERS,
  LayersState,
  LayerState,
  toLayersState,
  toLayerState,
} from "../shapes";

const initial = toLayersState(LAYERS);

export const layersReducer = createReducer<LayersState>(initial, (builder) => {
  builder
    .addCase(setLock, (state, action) => {
      if (!state[action.payload[0]]) {
        state[action.payload[0]] = toLayerState(LAYERS[action.payload[0]]);
        (state[action.payload[0]] as LayerState).lock = action.payload[1];
        return;
      } else {
        (state[action.payload[0]] as LayerState).lock = action.payload[1];
      }
    })
    .addCase(setVisiblity, (state, action) => {
      if (!state[action.payload[0]]) {
        state[action.payload[0]] = toLayerState(LAYERS[action.payload[0]]);
        (state[action.payload[0]] as LayerState).visible = action.payload[1];
        return;
      } else {
        (state[action.payload[0]] as LayerState).visible = action.payload[1];
      }
    })
    .addCase(clearCanvas, () => {
      return toLayersState(LAYERS);
    });
});
