import type { Reducer } from "redux";
import { Actions as GlobalActions } from "../actions/global";
import { zoom } from "../actions/zoom";
import { OSMZoomLevel } from "../lib/zoom";

// export type ZoomActions = Zoom | ZoomTo | ClearCanvas;

export type ZoomState = OSMZoomLevel;

const defaultState = 23.114_121_167_116_02;

export const zoomReducer: Reducer<ZoomState> = (
  state = defaultState,
  action
) => {
  if (zoom.match(action)) {
    return action.payload;
  }

  switch (action.type) {
    case GlobalActions.clearCanvas:
      return defaultState;
    default:
      return state;
  }
};
