import type { Reducer } from "redux";
import { Actions, EnablePan, DisablePan, SetPan } from "../actions/pan";

/**
 * Update, clear, reset viewport for the canvas
 */
export const panReducer: Reducer<boolean, EnablePan | DisablePan | SetPan> = (
  state = false,
  action
) => {
  switch (action.type) {
    case Actions.enablePan:
      return true;
    case Actions.disablePan:
      return false;
    case Actions.setPan:
      return action.pan;
    default:
      return state;
  }
};
