import { createReducer } from "@reduxjs/toolkit";
import { setFeature } from "../actions/features";

export type Features =
  | "place-on-line"
  | "boids-distribution"
  | "freedraw-tool"
  | "accent-color"
  | "stroke-color"
  | "line-tool"
  | "object-lock"
  | "object-visible"
  | "objects-list"
  | "image-shape"
  | "label-overlay";

export const featuresReducer = createReducer<{
  readonly [K in Features]?: boolean;
}>({}, (builder) => {
  builder.addCase(setFeature, (state, action) => {
    return { ...state, ...action.payload };
  });
});
