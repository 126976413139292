import type { Reducer } from "redux";

import { Actions, SetMapLong, SetMapLat, MoveMap } from "../actions/map";
import { Actions as GlobalActions, ClearCanvas } from "../actions/global";

export type MapActions = MoveMap | SetMapLong | SetMapLat | ClearCanvas;

export type MapState = {
  readonly zoom: number;
  readonly lat: number;
  readonly lng: number;
};

const defaultState = { zoom: 22, lat: -75, lng: 45 };

export const mapReducer: Reducer<MapState, MapActions> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case Actions.moveMap:
      return { zoom: state.zoom, lat: action.lat, lng: action.lng };
    case GlobalActions.clearCanvas:
      return { ...defaultState };
    default:
      return state;
  }
};

export default mapReducer;
