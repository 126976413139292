import { createReducer } from "@reduxjs/toolkit";
import { setDesignId } from "../actions/designs";
import { v4 as uuidv4 } from "uuid";

export const designsReducer = createReducer<string>(
	uuidv4(),
	(builder) => {
		return builder.addCase(setDesignId, (_, action) => {
			return action.payload;
		});
	}
);
