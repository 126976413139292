import React from "react";
import { createRoot, Root } from "react-dom/client";
// import ReactModal from "react-modal";
import { Provider } from "react-redux";
import type { Store } from "redux";
import { ThemeProvider } from "theme-ui";
import AppContainer from "../components/AppContainer";
import { EmbedContainer } from "../components/EmbedContainer";
import { RootState } from "./configureStore";
import theme from "../theme";

// Create one application wide react-dom/client root
// This may not be ideal in the long term
let root: Root | undefined = undefined;

export type SetupApp = (
	state: Readonly<Store<RootState>>
) => readonly [Root | undefined, Store<RootState>];

export const setupApp: SetupApp = (store) => {
	// ReactModal.setAppElement("#app");

	const renderUI = (
		Component: React.FunctionComponent,
		store: Readonly<Store>
	): readonly [Root | undefined, Store<RootState>] => {
		const element = document.getElementById("app");

		if (!element) {
			console.error("Could not find the app contianer");
			return [undefined, store];
		}

		if (!root) {
			root = createRoot(element);
		}

		root.render(
			<Provider store={store}>
				<Component />
			</Provider>
		);

		return [root, store];
	};

	return renderUI(AppContainer, store);
};

const EMBED_OVERLAY_ID = "embed-overlay";

export const setupEmbed: SetupApp = (store) => {
	const renderUI = (
		Component: React.FunctionComponent,
		store: Readonly<Store>
	): readonly [Root | undefined, Store<RootState>] => {
		const element = document.getElementById(EMBED_OVERLAY_ID);

		if (!element) {
			console.error("Could not find the embed container");
			return [undefined, store];
		}

		if (!root) {
			root = createRoot(element);
		}

		root.render(
			<>
				<Provider store={store}>
					<Component />
				</Provider>
			</>
		);

		return [root, store];
	};

	return renderUI(EmbedContainer, store);
};

const Error: React.FunctionComponent<ErrorProps> = ({ code, message }) => {
	return (
		<ThemeProvider theme={theme}>
			<div
				sx={{ backgroundColor: "alert", p: 2, borderRadius: 4, lineHeight: 1 }}
			>
				{code} {message}
			</div>
		</ThemeProvider>
	);
};

type ErrorProps = {
	readonly code: number;
	readonly message: string;
};
type SetupError = (props: ErrorProps) => Root | undefined;

export const setupError: SetupError = ({ code, message }) => {
	const renderUI = <T extends React.FC<ErrorProps>>(
		Component: T
	): Root | undefined => {
		const element = document.getElementById(EMBED_OVERLAY_ID);

		if (!element) {
			console.error("Could not find the embed container");
			return;
		}

		if (!root) {
			root = createRoot(element);
		}

		root.render(<Error code={code} message={message} />);

		return root;
	};

	return renderUI(Error);
};

export const unloadEmbedOverlay = (root: Root) => {
	const element = document.getElementById(EMBED_OVERLAY_ID);
	if (element) {
		root.unmount();
	}
};
