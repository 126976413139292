/**
 * Convert hexidecimal to RGBA
 */
export const hexToRgba = (hex: string): number[] => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const result = hex.replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d]{1,2})?$/i,
    (m, r, g, b, a) => "#" + r + r + g + g + b + b + (a || "")
  );

  const matches = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]*)?$/i.exec(
    result
  );

  if (!matches) {
    return [-1, -1, -1, -1];
  }

  let alpha = 100;

  if (matches[4]) {
    if (matches[4].length === 1) {
      alpha = parseInt(`${matches[4]}0`, 10);
    } else {
      alpha = parseInt(matches[4], 10);
    }
  }

  return [
    parseInt(matches[1], 16),
    parseInt(matches[2], 16),
    parseInt(matches[3], 16),
    alpha / 100,
  ];
};

/**
 * Convert hexidecimal to RGB
 */
export const hexToRgb = (hex: string): number[] => hexToRgba(hex).slice(0, 3);

/**
 * https://gist.github.com/mjackson/5311256
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   {number}  r       The red color value
 * @param   {number}  g       The green color value
 * @param   {number}  b       The blue color value
 * @return  {number[]}           The HSL representation
 */
// eslint-disable-next-line max-lines-per-function
export function rgbToHsl(
  r: number,
  g: number,
  b: number
): [number, number, number] {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2;
  let s = (max + min) / 2;
  const l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  return [h * 360, s * 100, l * 100];
}

/**
 * Convert hexidecimal to HSL
 */
export const hexToHsl = (hex: string): number[] => {
  const [r, g, b] = hexToRgb(hex);
  return rgbToHsl(r, g, b);
};

/**
 * Convert RGBA to HSLA
 */
export const rgbaToHsla = (
  r: number,
  g: number,
  b: number,
  a: number
): number[] => [...rgbToHsl(r, g, b), a];

/**
 * Convert hexidecimal to HSLA
 */
export const hexToHsla = (hex: string): number[] => {
  const [r, g, b, a] = hexToRgba(hex);
  return rgbaToHsla(r, g, b, a);
};

/**
 * Decimal percentage between 2 numbers
 */
export const percentageBetween = (num: number, end: number): number => {
  if (end < num) {
    return 1;
  }

  return num / end;
};

/**
 * Convert string to camel case
 * A Long Word - aLongWord
 */
export const toCamelCase = (str: string): string => {
  return str
    .split(" ")
    .map((word, index) => {
      // First word starts with a lowercase.
      if (index == 0) {
        return word.charAt(0).toLowerCase() + word.slice(1);
      }

      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");
};

/**
 * Convert BlobPart's to URL
 */
export const dataToUrl =
  (opts?: BlobPropertyBag) =>
  (data: BlobPart[]): string =>
    window.URL.createObjectURL(new Blob(data, opts));

export type MutatingStore<T> = {
  readonly setState: (newState: T) => void;
  readonly getState: () => T;
};

/**
 * Mutating store that works like redux store but not really at all.
 */
export const useMutatingStore = <T>(previousState: T): MutatingStore<T> => {
  // eslint-disable-next-line fp/no-let
  let state = previousState;

  return {
    setState(newState: T): void {
      // eslint-disable-next-line fp/no-mutation
      state = newState;
    },

    getState(): T {
      return state;
    },
  };
};

/**
 * Converts a string into a 32bit integer
 */
export const hashCode = (string: string): number => {
  // eslint-disable-next-line fp/no-let
  let hash = 0;
  // eslint-disable-next-line fp/no-let
  if (string.length == 0) return hash;
  // eslint-disable-next-line fp/no-let, fp/no-loops, fp/no-mutation
  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    // eslint-disable-next-line fp/no-mutation
    hash = (hash << 5) - hash + char;

    // eslint-disable-next-line fp/no-mutation
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Find the number from a string | number | undefined
 */
export const findNumber = (
  value: string | number | undefined
): number | undefined => {
  return !value
    ? typeof value === "number"
      ? value
      : undefined
    : typeof value === "number"
    ? value
    : Number.parseFloat(value);
};

export const dbg = <K>(val: K, ...args: readonly unknown[]): K => {
  console.log("dbg!", val, ...args);
  return val;
};

// (credits to Lam Wei Li)
// https://wiki.developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round$revision/1383484

export const round = function (number: number, precision?: number) {
  const shift = function (number: number, exponent: number) {
    const numArray = ("" + number).split("e");
    return +(
      numArray[0] +
      "e" +
      (numArray[1] ? +numArray[1] + exponent : exponent)
    );
  };
  precision = precision === undefined ? 0 : precision;
  return shift(Math.round(shift(number, +precision)), -precision);
};
