import { DBSchema, IDBPDatabase, openDB } from "idb";
import { EcogardenFabricImage } from "./fabric/objects";
import { fabric } from "fabric";
import { EcogardenImage } from "./objects";

export interface EcogardenImageDB extends DBSchema {
	readonly images: {
		readonly key: string;
		readonly value: Blob;
	};
}

export const loadImageDB = (): Promise<IDBPDatabase<EcogardenImageDB>> => {
	return openDB<EcogardenImageDB>("ecogarden-images", 1, {
		upgrade(db, oldVersion, newVersion, transaction) {
			switch (newVersion) {
				case 1:
					db.createObjectStore("images");
			}
		},
	});
};

export const loadImageFromStorage =
	(db: IDBPDatabase<EcogardenImageDB>) =>
	(
		opts: Partial<EcogardenImage>
	): Promise<EcogardenFabricImage | undefined> => {
		if (!opts.id) {
			return Promise.reject("loading image from storage, no id found in opts");
		}
		return db.get("images", opts.id).then((image) => {
			if (!image) {
				return Promise.reject(`image not found in storage for ${opts.id}`);
			}

			const imgUrl = window.URL.createObjectURL(image);
			return loadImage(opts)(imgUrl).then((img) => {
				// img.set("originX", "center");
				// img.set("originY", "center");
				img.set("id", opts.id);

				return img;
			});
		});
	};

export const loadImage =
	(opts: fabric.IImageOptions) =>
	(imgUrl: string): Promise<fabric.Image> => {
		return new Promise((resolve, reject) => {
			fabric.Image.fromURL(
				imgUrl,
				(img) => {
					img.set("subtype", "image");
					if (img.width === 0 && img.height === 0) {
						// invalid image loaded
						reject(
							"Could not load image or image was not loaded properly as it has no height or width"
						);
					}
					resolve(img);
				},
				opts
			);
		});
	};

export const setupImage = (img: EcogardenFabricImage): void => {
	if (!img) {
		return;
	}

	img.set("originX", "center");
	img.set("originY", "center");

	// img.sendToBack();
	img.setCoords();
};

/**
 * Convert dataUrl to Blob
 */
export const toBlob = async (dataUrl: string): Promise<Blob> => {
	return fetch(dataUrl).then((res) => res.blob());
};
