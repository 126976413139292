import { padWithZero } from "./string";
import { validateDesign } from "./design";

/**
 * Generate a filename. Defaults to using generated string with the date.
 */
export const generateFilename =
  (extension: string) =>
  (name: unknown): string => {
    if (!name) {
      const date = new Date();

      const pad = padWithZero(2);

      const filename = `Ecogarden-${pad(date.getMonth() + 1)}-${pad(
        date.getDay()
      )}-${pad(date.getFullYear())}_${pad(date.getHours())}_${pad(
        date.getMinutes()
      )}_${pad(date.getSeconds())}`;

      return encodeURIComponent(`${filename}.${extension}`);
    }

    return encodeURIComponent(`${name}.${extension}`);
  };

export interface FileReaderEventTarget extends EventTarget {
  readonly result?: string | ArrayBuffer | null;
  readonly files?: FileList;
}

export interface FileReaderEvent
  extends Partial<ProgressEvent<FileReaderEventTarget>> {
  readonly target?: FileReaderEventTarget | null;
}

/**
 * Load from FileReader'
 * - Using file reader, we access the file results.
 * - Then validate the design to have the required attributes
 * - Then on finish, pass the parsed design to onFinishLoad
 */
export const loadFromFile =
  (onFinishLoad: (parsed?: unknown) => void) =>
  // @ts-ignore
  async (e): Promise<void> => {
    if (!e.target) {
      throw new Error("Invalid target.");
    }

    if (!e.target.result) {
      throw new Error("Invalid result.");
    }

    if (e.target.result instanceof ArrayBuffer) {
      console.log("Not a string result.");
      return;
    }

    const parsed = JSON.parse(e.target.result);

    // Validate parsed input.
    return validateDesign(parsed).then(onFinishLoad);
  };

export const handleLoadFile =
  (reader: { readonly current: FileReader }) =>
  (onFinishLoad: (parsed?: unknown) => void) =>
  async (e: FileReaderEvent): Promise<void> => {
    if (!e.target || e.target === null) {
      console.log("Invalid target.");
      return;
    }

    const target = e.target;

    reader.current.addEventListener("load", loadFromFile(onFinishLoad));

    reader.current.addEventListener("error", () => {
      console.error("Error loading ");
    });

    const files = target.files;

    if (!files) {
      console.log("No files attached.");
      return;
    }

    Array.from(files).map((file) => {
      reader.current.readAsText(file);
    });
  };
