import { fabric } from "fabric";
import { EcogardenFabricPath } from "../fabric/objects";

export type PathOptions = {
  readonly path: string;
};

/**
 * Load image from url into a image element and use the image as an object url
 */
const loadImageFetch = async (url: string): Promise<HTMLImageElement> =>
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const img = document.createElement("img");

      img.src = URL.createObjectURL(blob);
      return img;
    });

/**
 * Load path with image from url
 */
export const loadPath =
  (url: string) =>
  async ({ path, ...opts }: fabric.IPathOptions): Promise<fabric.Path> => {
    return loadImageFetch(url).then((img: HTMLImageElement) => {
      const shapeOpts = {
        ...opts,
        fill: new fabric.Pattern({ source: img }),
      };

      return createPath(shapeOpts)(path);
    });
  };

/**
 * Create a path
 */
export const createPath =
  (opts: fabric.IPathOptions) =>
  (path: string | fabric.Point[] | undefined): EcogardenFabricPath => {
    const pathObj = new fabric.Path(path, { ...defaultPathOpts, ...opts });

    return attachPathEvents(pathObj);
  };

export const attachPathEvents = (pathObj: fabric.Path) => {
  pathObj.on("selected", () => {
    pathObj.set("perPixelTargetFind", false);
  });

  pathObj.on("deselected", () => {
    pathObj.set("perPixelTargetFind", true);
  });
  return pathObj;
};

export const defaultPathOpts = {
  perPixelTargetFind: true,
  selectable: false,
  // hasControls: false,
  hoverCursor: "default",
  lockUniScaling: true,
  stroke: "black",
};
