export type PathSizes = "small" | "medium" | "large";

export const sizeToNumber = (size?: PathSizes): number => {
  if (size === "small") {
    return 2;
  }

  if (size === "medium") {
    return 4;
  }

  if (size === "large") {
    return 8;
  }

  return 2;
};

export const numberToSize = (num?: number): PathSizes => {
  if (num === 2) {
    return "small";
  }

  if (num === 4) {
    return "medium";
  }

  if (num === 8) {
    return "large";
  }

  return "medium";
};

export default { sizeToNumber, numberToSize };
