import type { Reducer } from "redux";

import { Actions, Open, Close, CloseAll } from "../actions/panels";

const panels = { selection: false, settings: false, shapes: false };

type State = typeof panels;

type ReducerActions = Open | Close | CloseAll;

const panelsReducer: Reducer<State, ReducerActions> = (
  state = panels,
  action
) => {
  switch (action.type) {
    case Actions.open:
      return { ...state, ...{ [action.key]: true } };
    case Actions.close:
      return { ...state, ...{ [action.key]: false } };
    case Actions.closeAll:
      return { ...panels };
    default:
      return state;
  }
};

export default panelsReducer;
