/* eslint-disable max-nested-callbacks */
/* eslint-disable max-lines-per-function */
/* eslint-disable functional/prefer-readonly-type */
import { createReducer } from "@reduxjs/toolkit";
import { clearCanvas, loadDesign } from "../actions/global";
import {
	addObject,
	addObjects,
	deleteObject,
	deleteObjects,
	modifiedObject,
	setObjects,
	sortObjects,
} from "../actions/objects";
import type { EcogardenObjects } from "../lib/objects";
import { sortCanvasObjects } from "../shapes";

export const objectsReducer = createReducer<readonly EcogardenObjects[]>(
	[],
	(builder) => {
		builder
			.addCase(addObject, (state, action) => {
				state.push(action.payload);
				state.sort(sortCanvasObjects);
			})
			.addCase(addObjects, (state, action) => {
				action.payload.forEach((obj) => state.push(obj));
				state.sort(sortCanvasObjects);
				return state;
			})
			.addCase(deleteObjects, (state, action) => {
				return state.filter(
					(object) => object.id && !action.payload.includes(object.id)
				);
			})
			.addCase(deleteObject, (state, action) => {
				return state.filter((object) => action.payload !== object.id);
			})
			.addCase(modifiedObject, (state, action) => {
				return state.map((object) => {
					if (action.payload.id !== object.id) {
						return object;
					}

					return { ...object, ...action.payload };
				});
			})
			.addCase(loadDesign, (_state, action) => {
				return action.payload.objects;
			})
			.addCase(clearCanvas, (_state, _action) => {
				return [];
			})
			.addCase(setObjects, (_state, action) => {
				return [...action.payload].sort(sortCanvasObjects);
			})
			.addCase(sortObjects, (state) => {
				return [...state].sort(sortCanvasObjects);
			});
	}
);
