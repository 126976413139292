export type TextSizes = "s" | "m" | "l";

export const sizeToNumber = (size?: TextSizes): number => {
  if (size === "s") {
    return 24;
  }

  if (size === "m") {
    return 36;
  }

  if (size === "l") {
    return 48;
  }

  return 36;
};

export const numberToSize = (size?: number): TextSizes => {
  if (size === 24) {
    return "s";
  }

  if (size === 36) {
    return "m";
  }

  if (size === 48) {
    return "l";
  }

  return "m";
};
