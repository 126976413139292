import { createReducer } from "@reduxjs/toolkit";
import { clearCanvas, loadDesign } from "../actions/global";
import { setTranslation, updateViewport, Viewport } from "../actions/viewport";

const defaultViewport: Viewport = [1, 0, 0, 1, 0, 0];

/**
 * Update, clear, reset viewport for the canvas
 */
export const viewportReducer = createReducer<Readonly<Viewport>>(
  defaultViewport,
  (builder) => {
    builder
      .addCase(updateViewport, (_, action) => {
        return action.payload;
      })
      .addCase(setTranslation, (state, action) => {
        state[4] = action.payload.x;
        state[5] = action.payload.y;
      })
      .addCase(clearCanvas, () => {
        return [...defaultViewport];
      })
      .addCase(loadDesign, (_, action) => {
        return [...action.payload.viewport];
      });
  }
);
