/**
 * Capitalize each word
 */
export const capitalize = (name: string): string =>
  name
    .split(" ")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");

/**
 * Convert dashes to spaces
 */
export const dashesToSpaces = (name: string): string =>
  name.replace(/-/gi, " ");

/**
 * Pads a number with 0.
 * (2)(1) = '01'
 * (4)(1) = '0001'
 */
export const padWithZero =
  (padding: number) =>
  (number: number): string =>
    String(number).padStart(padding, "0");
