import { Reducer } from "redux";
import {
  Actions,
  HideDebug,
  SetSiteName,
  ShowDebug,
} from "../actions/settings";

const DEFAULT_STATE = { debug: false, name: "" };

type SettingsActions = SetSiteName | ShowDebug | HideDebug;

export const settingsReducer: Reducer<typeof DEFAULT_STATE, SettingsActions> = (
  state = DEFAULT_STATE,
  action
) => {
  switch (action.type) {
    case Actions.setSiteName:
      return { ...state, name: action.name };
    case Actions.showDebug:
      return { ...state, debug: true };
    case Actions.hideDebug:
      return { ...state, debug: false };
    default:
      return state;
  }
};
