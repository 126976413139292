/**
 * Profiling scales up to different levels.
 * Default we profile using start and stop.
 */

export enum Profiling {
  Trace, // For seeing a full range of profiling options being processed.
  Debug, // When debug mode is enabled (separate of logging debug) it will show other debug options
  Always, // This level will always show. Default profiles use this level
}

let PROFILING_LEVEL =
  BUILDSTAGE === "development" ? Profiling.Debug : Profiling.Always;

// PROFILING_LEVEL = Profiling.Always

export const getProfilingLevel = () => PROFILING_LEVEL;

export const profiler = (level: Profiling) => {
  return { start: start(level), stop: stop(level) };
};

const startWhen = (level: Profiling) => (key: string) => {
  if (level >= PROFILING_LEVEL) {
    return start(level)(key);
  }

  return undefined;
};

/**
 * Start profiling using key
 *
 * Returns the key to allow for easy usage with variables
 *
 * ```
 * const key = profiling.start('yo')
 * ```
 */
const start =
  (level: Profiling) =>
  (key: string): string => {
    if (level >= PROFILING_LEVEL) {
      console.time(key);
    }

    return key;
  };

/**
 * Stop profiling on a key
 *
 * ```
 * const key = profiling.start('yo')
 * profiling.stop(key)
 * ```
 */
const stop = (level: Profiling) => (key: string | undefined) => {
  if (level >= PROFILING_LEVEL) {
    console.timeEnd(key);
  }
};

export default { start, stop, startWhen };
