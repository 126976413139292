import type { Reducer } from "redux";
import type { SetCanvas } from "../actions/canvas";

import { Actions } from "../actions/canvas";

export type CanvasState = string;

type CanvasActions = SetCanvas;

const canvasReducer: Reducer<CanvasState, CanvasActions> = (
  state = "",
  action
) => {
  switch (action.type) {
    case Actions.setCanvas:
      return "" + action.canvas;
    default:
      return state;
  }
};

export default canvasReducer;
