/* eslint-disable max-nested-callbacks */
/* eslint-disable max-lines-per-function */
/* eslint-disable functional/prefer-readonly-type */
import { createReducer } from "@reduxjs/toolkit";
import { setFileTemplate, setSaveTo } from "../actions/tauri";

export type TauriState = {
	saveToDir?: string;
	fileTemplate?: string;
};

export const tauriReducer = createReducer<TauriState>({}, (builder) => {
	builder
		.addCase(setSaveTo, (state, action) => {
			return { ...state, saveToDir: action.payload };
		})
		.addCase(setFileTemplate, (state, action) => {
			return { ...state, fileTemplate: action.payload };
		});
});
