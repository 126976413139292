import { createReducer } from "@reduxjs/toolkit";
import { addCopy, clearCopy } from "../actions/copy";
import { clearCanvas } from "../actions/global";
import { EcogardenObjects } from "../lib/objects";

export const copyReducer = createReducer<readonly EcogardenObjects[]>(
  [],
  (builder) => {
    builder
      .addCase(addCopy, (state, action) => {
        state.push(...action.payload);
      })
      .addCase(clearCopy, () => {
        return [];
      })
      .addCase(clearCanvas, (_state, _action) => {
        return [];
      });
  }
);
