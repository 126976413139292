export type Point = {
  x: number;
  y: number;
};

/**
 * Clamp a number between min and max
 */
export const clamp = (num: number, min: number, max: number): number =>
  Math.min(Math.max(num, min), max);

/**
 * Middle of 2 points
 */
export const middle =
  (a: Point) =>
  (b: Point): Point => {
    return { x: (a.x + b.x) / 2, y: (a.y + b.y) / 2 };
  };

/**
 * Diff of 2 points
 */
export const diff =
  (a: Point) =>
  (b: Point): number => {
    return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
  };

/**
 * Delta between 2 points
 */
export const delta =
  (a: Point) =>
  (b: Point): Point => {
    const result = {
      x: a.x - b.x,
      y: a.y - b.y,
    };
    return result;
  };

// type Point = { readonly x: number; readonly y: number };
export const subtract =
  (p1: Point) =>
  (p2: Point): Point => ({
    x: p2.x - p1.x,
    y: p2.y - p1.y,
  });

type TopLeft = { readonly left: number; readonly top: number };

export const diffCenter =
  (p1: Point) =>
  (p2: TopLeft): Point => ({
    x: p2.left - p1.x,
    y: p2.top - p1.y,
  });
