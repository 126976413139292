import { loadSVG } from "../../lib/fabric";
import type {
  FabricGroupObject,
  IEcogardenObjectOptions,
} from "../fabric/objects";
import type { fabric } from "fabric";
import { Shape } from "../../shapes";

export interface EcogardenIObjectOptions extends fabric.IObjectOptions {
  readonly id?: string;
  readonly subtype?: Shape;
  // readonly originX?: "left" | "right" | "center";
  // readonly originY?: "left" | "right" | "center";
  readonly originX?: string;
  readonly originY?: string;
}

export const loadShape = (
  file: string,
  { id, ...options }: EcogardenIObjectOptions & fabric.IObjectOptions
): Promise<FabricGroupObject> => {
  const shapeOptions: IEcogardenObjectOptions = {
    originX: "center",
    originY: "center",
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    hasControls: false,
    hasBorders: false,
    hasRotatingPoint: false,
    perPixelTargetFind: true,
    strokeUniform: true,
    statefullCache: true,
    id,
    ...options,
  };

  return loadSVG(file, shapeOptions);
};
