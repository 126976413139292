export enum LogLevel {
  Trace,
  Debug,
  Notice,
  Info,
  Warning,
  Error,
  Fatal,
}

// eslint-disable-next-line fp/no-let, functional/no-let
let LOG_LEVEL = LogLevel.Warning;

// LOG_LEVEL = LogLevel.Debug;
// LOG_LEVEL = LogLevel.Info;
// LOG_LEVEL = LogLevel.Trace;

export const toString = (level: LogLevel): string => {
  if (level === LogLevel.Trace) {
    return "Trace";
  }
  if (level === LogLevel.Debug) {
    return "Debug";
  }

  if (level === LogLevel.Notice) {
    return "Notice";
  }

  if (level === LogLevel.Info) {
    return "Info";
  }

  if (level === LogLevel.Warning) {
    return "Warning";
  }

  if (level === LogLevel.Error) {
    return "Error";
  }

  if (level === LogLevel.Fatal) {
    return "Fatal";
  }

  return "undefined";
};

export const setLogLevel = (level: LogLevel): void => {
  LOG_LEVEL = level;
};

export const getLogLevel = (): LogLevel => {
  return LOG_LEVEL;
};

const log = (level: LogLevel, ...args: readonly unknown[]) => {
  if (level >= getLogLevel()) {
    // If a function is the second argument, execute it.
    // This is to allow things that might be expensive to
    // only be run when we will be logging it by executing the function
    if (typeof args[0] === "function") {
      console.log(toString(level), args[0]());
      return;
    }

    if (typeof args[1] === "function") {
      console.log(toString(level), args[0], args[1]());
      return;
    }

    console.log(toString(level), ...args);
  }
};

const trace = (...args: readonly unknown[]): void => {
  log(LogLevel.Trace, ...args);
};

const debug = (...args: readonly unknown[]): void => {
  log(LogLevel.Debug, ...args);
};

const notice = (...args: readonly unknown[]): void => {
  log(LogLevel.Notice, ...args);
};

const info = (...args: readonly unknown[]): void => {
  log(LogLevel.Info, ...args);
};

const warning = (...args: readonly unknown[]): void => {
  log(LogLevel.Warning, ...args);
};

const error = (...args: readonly unknown[]): void => {
  log(LogLevel.Error, ...args);
};

const fatal = (...args: readonly unknown[]): void => {
  log(LogLevel.Fatal, ...args);
};

const pass =
  (level: LogLevel) =>
  <T extends unknown | unknown[]>(value: T): T => {
    if ("length" in (value as unknown[]) && (value as unknown[]).length > 0) {
      log(level, ...(value as unknown[]));
    } else {
      log(level, value);
    }

    return value;
  };

const time =
  (level: LogLevel) =>
  (value: string): string => {
    if (level >= getLogLevel()) {
      console.time(value);
    }

    return value;
  };

const timeEnd =
  (level: LogLevel) =>
  (value: string): string => {
    if (level >= getLogLevel()) {
      console.timeEnd(value);
    }

    return value;
  };

const dbg = <K>(val: K, ...args: readonly unknown[]): K => {
  console.log(val, ...args);
  return val;
};

export default {
  trace,
  debug,
  notice,
  info,
  warning,
  error,
  fatal,
  pass,
  time,
  timeEnd,
  dbg,
};
