import { clamp } from "lodash";
import normalizeWheel from "normalize-wheel";

export type OSMZoomLevel = number;

/**
 * Upper OSM zoom level
 */
const LOWER_BOUND = 18;

/**
 * Lower OSM zoom level
 */
const UPPER_BOUND = 26;

/**
 * Is zoom calculation out of bounds?
 * Zoom levels 18 to 24
 */
export const zoomOutOfBounds = (value: number): boolean =>
	value < LOWER_BOUND || value > UPPER_BOUND;

/**
 * Find a value that is within UPPER_BOUND and LOWER_BOUND
 */
export const inBounds = (value: number): number =>
	Math.min(Math.max(value, LOWER_BOUND), UPPER_BOUND);

/**
 * Clamp zoom between LOWER_BOUND and UPPER_BOUND
 */
export const clampZoom = (zoomLevel: OSMZoomLevel): number =>
	clamp(zoomLevel, LOWER_BOUND, UPPER_BOUND);

/**
 * Direction we are zooming
 */
export enum ZoomDirection {
	In,
	Out,
}

/**
 * Earth Circumference
 * 40 075 016.686 m ≈ 2π ∙ 6 378 137.000 m for the reference geoid used by OpenStreetMap
 * https://wiki.openstreetmap.org/wiki/Zoom_levels
 */
export const EQUATORIAL_CIRCUMFERENCE = 40_075_016.686;

/**
 * Feet per meter conversion
 */
export const FEET_PER_METER = 3.280_84;

/**
 * Size of tiles from OSM. Mapbox GL runs at 512
 */
export const TILE_SIZE = 256;

/**
 * Convert angle (0 - 360) to radians
 */
export const toRadians = (angle: number): number => angle * (Math.PI / 180);

/**
 * meters per pixel
 * Spixel = C ∙ cos(latitude) / 2 ^ (zoomlevel + 8)
 */

export type Feet = number;
export type Meters = number;

export const OSMZoomLevelToMetersPerPixel =
	(latitudeDegrees: number) =>
	(OSMZoomLevel: OSMZoomLevel): Meters =>
		(EQUATORIAL_CIRCUMFERENCE * Math.cos(toRadians(latitudeDegrees))) /
		Math.pow(2, OSMZoomLevel + 8);

/**
 * Convert OSM zoom level to feet per pixel
 * @example OSMZoomLevelToFeetPerPixel(45)(23)
 */
export const OSMZoomLevelToFeetPerPixel =
	(latitude: number) =>
	(OSMZoomLevel: OSMZoomLevel): Feet =>
		OSMZoomLevelToMetersPerPixel(latitude)(OSMZoomLevel) * FEET_PER_METER;

/**
 * Convert meters to feet
 * @example metersToFoot(5)
 */
export const meterToFoot = (meters: number): number => {
	return meters * FEET_PER_METER;
};

/**
 * Convert feet to meters
 */
export const footToMeter = (feet: number): number => {
	return feet / FEET_PER_METER;
};

/**
 * Convert meters per pixel to OSM zoom level
 */
export const metersPerPixelToOSMZoomLevel =
	(latitude: number) =>
	(metersPerPixel: Meters): OSMZoomLevel =>
		Math.log2(
			(EQUATORIAL_CIRCUMFERENCE * Math.cos(toRadians(latitude))) /
				metersPerPixel
		) - 8;

/**
 * Convert feet per pixel to OSM zoom level
 */
export const feetPerPixelToOSMZoomLevel =
	(latitude: number) =>
	(feetPerPixel: Meters): OSMZoomLevel =>
		metersPerPixelToOSMZoomLevel(latitude)(footToMeter(feetPerPixel));

/**
 * Convert OpenStreetMap Zoom Level to Pixels Per Foot
 * Meters to feet = 1:3.28084 (zoom 1.0:0.08333333333)
 */
// export const OSMZoomLevelToFeetPerPixel =
//   (latitude: number) =>
//   (zoomLevel: OSMZoomLevel): Feet =>
//     feetPerPixel(latitude)(zoomLevel);

/**
 * ZoomDirection of WheelEvent
 */
export const wheelZoomDirection = (
	event: Readonly<WheelEvent>
): ZoomDirection => {
	const wheel = normalizeWheel(event);

	return wheel.spinY > 0 ? ZoomDirection.Out : ZoomDirection.In;
};
