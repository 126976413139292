/* eslint-disable max-lines */
import { fabric } from "fabric";
import { array, number, object, string } from "yup";
import {
	findDefaultPixelSize,
	findShapeDetail,
	findShapeLayer,
	getAvailableSizes,
	Layer,
	Shape,
} from "../shapes";
import {
	EcogardenFabricLine,
	EcogardenFabricObjects,
	EcogardenFabricPath,
} from "./fabric/objects";
import { numberToSize as pathNumberToSize, PathSizes } from "./path";
import { numberToSize, TextSizes } from "./text";

/**
 * targeting 25 pixels per foot, 0.4 feet per pixel
 */
export const PIXELS_PER_FEET = 25;
export const PIXELS_PER_METER = 25 * 3.280_84;
export const FEET_PER_PIXEL = 1 / PIXELS_PER_FEET;
export const METERS_PER_PIXEL = 1 / PIXELS_PER_METER;

export const objectSchema = object().shape({
	type: string().required(),
	subtype: string().required(),
	width: number().positive(),
	height: number().positive(),
	originX: string(),
	originY: string(),
	scaleX: number().positive(),
	scaleY: number().positive(),
	angle: number(),
	id: string().required(),
	left: number().required(),
	top: number().required(),
	label: string(),
	points: array(),
	rx: number(),
	ry: number(),
	cx: number(),
	cy: number(),
	size: string(),
});

export type EcogardenObjects =
	| EcogardenObject
	| EcogardenPolygon
	| EcogardenRect
	| EcogardenEllipse
	| EcogardenTextbox
	| EcogardenPath
	| EcogardenLine
	| EcogardenImage;

export type EcogardenObject = {
	/**
	 * Type of basic shape (group, rect, circle, polygon)
	 */
	readonly type: string | undefined;

	/**
	 * Type of shape (lawn, perennial)
	 */
	readonly subtype: Shape | undefined;

	/**
	 * originX allows for 0-1 positioning of the origin
	 */
	readonly originX?: "left" | "right" | "center" | number;

	/**
	 * originY allows for 0-1 positioning of the origin
	 */
	readonly originY?: "left" | "right" | "center" | number;

	/**
	 * Width of the object in the Y axis
	 */
	readonly width: number | undefined;

	/**
	 * Height of the object in the Y axis
	 */
	readonly height: number | undefined;

	/**
	 * Scale in the X axis
	 */
	readonly scaleX?: number | undefined;

	/**
	 * Scale in the Y axis
	 */
	readonly scaleY?: number | undefined;

	/**
	 * Rotation angle
	 */
	readonly angle: number | undefined;

	/**
	 * UUID of the object
	 */
	readonly id: string;

	/**
	 * Positioning left, typically origin on center
	 */
	readonly left: number | undefined;

	/**
	 * Positioning top, typically origin on center
	 */
	readonly top: number | undefined;

	/**
	 * Label of the object
	 */
	readonly label?: string | undefined;

	/**
	 * Fill color of the object. Only updates fills that are set.
	 */
	readonly fill?: string;

	/**
	 * Stroke color for the object. Currently only effective for line type objects
	 */
	readonly stroke?: string;

	/**
	 * Is the object locked, preventing movement and selection of this object.
	 */
	readonly lock?: boolean;

	/**
	 * Is the object visible? Visibility does not directly prevent selection, and may be visible.
	 */
	readonly visible?: boolean;

	/**
	 * Size of the text where text is used.
	 */
	readonly size?: TextSizes;
};

export interface EcogardenPolygon extends EcogardenObject {
	/**
	 * Polygon points
	 */
	// eslint-disable-next-line functional/prefer-readonly-type
	readonly points?: { x: number; y: number }[];

	/**
	 * Offset for the path, the position of x and y.
	 */
	readonly pathOffset?: { x: number; y: number };
}

export interface EcogardenRect extends EcogardenObject {
	/**
	 * Radius of the rectangle in the X axis
	 */
	readonly rx?: number;

	/**
	 * Radius of the rectangle in the Y Axis
	 */
	readonly ry?: number;
}

export interface EcogardenLine extends EcogardenObject {
	/**
	 * Starting position for the line in the X axis
	 */
	readonly x1?: number;

	/**
	 * Starting position for the line in the Y axis
	 */
	readonly y1?: number;

	/**
	 * Ending position for the line in the X axis
	 */
	readonly x2?: number;

	/**
	 * Ending position for the line in the Y axis
	 */
	readonly y2?: number;

	/**
	 * Color of the stroke used in the line
	 */
	readonly stroke?: string;

	/**
	 * Size of the line
	 */
	readonly strokeWidth?: PathSizes;
}

export interface EcogardenEllipse extends EcogardenObject {
	/**
	 * The radius of the ellipse on the x axis.
	 */
	readonly rx?: number;

	/**
	 * The radius of the ellipse on the y axis.
	 */
	readonly ry?: number;

	/**
	 * The x position of the ellipse.
	 */
	readonly cx?: number;

	/**
	 * The y position of the ellipse.
	 */
	readonly cy?: number;
}

export interface EcogardenTextbox extends EcogardenObject {
	/**
	 * Text shown in the textbox
	 */
	readonly text: string;

	/**
	 * Size of the text
	 */
	readonly size?: TextSizes;
}

export interface EcogardenPath extends EcogardenObject {
	/**
	 * Defines the shape of the path
	 */
	readonly path?: string | fabric.Point[];

	/**
	 * Offset for the path, the position of x and y.
	 */
	readonly pathOffset?: { readonly x: number; readonly y: number };

	/**
	 * Color of the path
	 */
	readonly stroke?: string;

	/**
	 * Size of the path
	 */
	readonly strokeWidth?: PathSizes;
}

export interface EcogardenImage extends EcogardenObject {}

/**
 * A point in space.
 */
type Point = { x: number; y: number };

/**
 * Convert Fabric Object to Ecogarden Object
 */
// eslint-disable-next-line max-lines-per-function, complexity
export const toEcogardenObject = <T extends EcogardenFabricObjects>(
	fabricObject: T
): EcogardenObjects => {
	// const fabricObject = object.toObject([
	//   "id",
	//   "points",
	//   "pathOffset",
	//   "rx",
	//   "ry",
	// ]) as fabric.IObjectOptions &
	//   fabric.IPolylineOptions &
	//   fabric.ITextboxOptions &
	// fabric.IRectOptions & { label?: string } ;

	// TODO: Not using selection adjustments
	// const selectionAdjustments: Partial<{ top: number; left: number }> = {};

	// if (fabricObject.group && fabricObject.width && fabricObject.height) {
	// 	const { top, left } = objectPositionOnCanvas(fabricObject);
	// 	selectionAdjustments.top = top;
	// 	selectionAdjustments.left = left;
	// }

	// Optional components
	const polylineOptions: Partial<fabric.IPolylineOptions> = {};

	// Polygon points
	if ("points" in fabricObject) {
		polylineOptions.points = [...(fabricObject.points ?? [])];
		polylineOptions.pathOffset = fabricObject.pathOffset;
	}

	// Optional components
	const pathOptions: {
		path?: fabric.Point[] | Point;
		pathOffset?: fabric.Point | Point;
		stroke?: string;
		strokeWidth?: PathSizes;
	} = {};

	//
	// Path points
	if ("path" in fabricObject && fabricObject.type === "path") {
		pathOptions.path = [...((fabricObject as EcogardenFabricPath).path ?? [])];
		pathOptions.pathOffset = (fabricObject as EcogardenFabricPath).pathOffset;
		if (
			fabricObject.stroke !== "black" &&
			fabricObject.stroke !== "rgb(0,0,0)"
		) {
			pathOptions.stroke = fabricObject.stroke;
		}
		pathOptions.strokeWidth = pathNumberToSize(fabricObject.strokeWidth);
	}

	const lineOptions: Partial<
		Omit<fabric.ILineOptions, "strokeWidth" | "stroke"> & {
			strokeWidth: PathSizes;
			stroke: string;
		}
	> = {};

	// Polygon points
	if (fabricObject.type === "line") {
		const { x1, y1, x2, y2 } = (
			fabricObject as EcogardenFabricLine
		).calcLinePoints();
		lineOptions.x1 = x1;
		lineOptions.y1 = y1;
		lineOptions.x2 = x2;
		lineOptions.y2 = y2;

		if (
			fabricObject.stroke !== "black" &&
			fabricObject.stroke !== "rgb(0,0,0)"
		) {
			lineOptions.stroke = fabricObject.stroke;
		}

		lineOptions.strokeWidth = pathNumberToSize(fabricObject.strokeWidth);
	}

	const rectOptions: Partial<fabric.IRectOptions> = {};

	if ("rx" in fabricObject) {
		rectOptions.rx = fabricObject.rx;
		rectOptions.ry = fabricObject.ry;
	}

	const textboxOptions: Partial<fabric.ITextboxOptions & { size: TextSizes }> =
		{};

	if ("text" in fabricObject) {
		textboxOptions.text = fabricObject.text;
		textboxOptions.size = numberToSize(fabricObject.fontSize);
	}

	const fillOptions: { fill?: typeof fabric.Object.prototype.fill } = {};

	// if ("fill" in fabricObject && fabricObject.fill !== null) {
	//   fillOptions.fill = fabricObject.fill;
	// }

	const labelOptions: { label?: string } = {};

	// Labels are run outside of fabric now so converting
	// a fabric object to ecogarden object shouldn't try to port it over
	// if ("label" in fabricObject && fabricObject.label !== undefined) {
	//   labelOptions.label = fabricObject.label;
	// }

	const scaleOptions: { scaleX?: number; scaleY?: number } = {
		scaleX: fabricObject.scaleX,
		scaleY: fabricObject.scaleY,
	};

	// if (
	//   "scaleX" in fabricObject &&
	//   fabricObject.scaleX != undefined &&
	//   fabricObject.scaleX !== 1
	// ) {
	//   scaleOptions.scaleX = fabricObject.scaleX;
	// }

	// if (
	//   "scaleY" in fabricObject &&
	//   fabricObject.scaleY != undefined &&
	//   fabricObject.scaleY !== 1
	// ) {
	//   scaleOptions.scaleY = fabricObject.scaleY;
	// }

	const angleOptions: { angle?: number } = {
		angle: fabricObject.angle,
	};

	// if (
	//   "angle" in fabricObject &&
	//   fabricObject.angle !== undefined &&
	//   fabricObject.angle !== 0
	// ) {
	//   angleOptions.angle = fabricObject.angle;
	// }

	const sizeOptions: { width?: number; height?: number } = {
		width: fabricObject.width,
		height: fabricObject.height,
	};

	// TODO: Possibly remove default values from being set as an
	// ecogarden object value to reduce size. Making these values
	// not exist can cause problems.
	//
	// if (
	//   "width" in fabricObject &&
	//   fabricObject.width !== undefined &&
	//   fabricObject.width !==
	//     findDefaultWidth(fabricObject.subtype as Shape | undefined)
	// ) {
	//   sizeOptions.width = fabricObject.width;
	// }

	// if ("height" in fabricObject && fabricObject.height !== undefined) {
	//   const defaultHeight = findDefaultHeight(
	//     fabricObject.subtype as Shape | undefined
	//   );

	//   if (defaultHeight && fabricObject.height) {
	//     if (Math.abs(fabricObject.height - defaultHeight) > 1) {
	//       console.log(Math.abs(fabricObject.height ?? 300 - defaultHeight) > 1);
	//       console.log(fabricObject.height === defaultHeight);
	//       sizeOptions.height = fabricObject.height;
	//     }
	//   }
	// }

	return {
		type: fabricObject.type,
		subtype: fabricObject.subtype,
		top: fabricObject.top,
		left: fabricObject.left,

		id: fabricObject.id,
		// ...positionOptions,
		...angleOptions,
		...scaleOptions,
		...sizeOptions,
		...labelOptions,
		...fillOptions,
		...polylineOptions,
		...rectOptions,
		...textboxOptions,
		...pathOptions,
		...lineOptions,
	} as EcogardenObject;
};

/**
 * Find an Ecogarden Object by id in a list of Ecogarden Objects
 */
export const findObject =
	<T extends EcogardenObject>(objects: readonly T[]) =>
	(id: string | undefined): T | undefined => {
		if (!id) {
			return undefined;
		}

		return objects.find((object_) => object_.id === id);
	};

/**
 * Get size of object in diameter in pixels.
 * Currently works for circles only (diameter is same on x/y)
 */
export const getScaledWidth = (
	object_?: EcogardenObjects | EcogardenFabricObjects
): number | undefined => {
	if (!object_ || !object_.width || !object_.scaleX) {
		return undefined;
	}

	return object_.width * object_.scaleX;
};

/**
 * Find the height/depth of the EcogardenObject
 */
export const getScaledHeight = (
	object_: EcogardenObjects | EcogardenFabricObjects
): number | undefined => {
	if (!object_ || !object_.height || !object_.scaleY) {
		return undefined;
	}

	return object_.height * object_.scaleY;
};

/**
 * Convert a pixel measurement to the measurement in feet.
 * 25 pixels per foot
 * Used to determine real life measurements of shapes.
 */
export const pixelsToFeet = (
	pixels: number | undefined
): number | undefined => {
	return pixels ? pixels / PIXELS_PER_FEET : undefined;
};

export const feetToPixels = (feet: number): number => {
	return feet * PIXELS_PER_FEET;
};

const distance =
	(a: Point) =>
	(b: Point): number => {
		return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
	};

/**
 * Add object size to string
 */
// eslint-disable-next-line max-lines-per-function, complexity
export const addSize = (
	object: Readonly<EcogardenObjects> | Readonly<EcogardenFabricObjects>
) => {
	// Calculate distance for lines
	if (
		object.type === "line" &&
		object.width !== undefined &&
		object.height !== undefined &&
		object.left !== undefined &&
		object.top !== undefined
	) {
		// Check if we are using a fabric object and can get the actual line points from the canvas
		if (!("calcLinePoints" in object)) {
			const { x1, y1, x2, y2 } = object as EcogardenFabricLine | EcogardenLine;

			// make sure we have some value for each
			if (
				x1 === undefined ||
				y1 === undefined ||
				x2 === undefined ||
				y2 === undefined
			) {
				return "";
			}

			return `${(
				distance({ x: x1, y: y1 })({
					x: x2,
					y: y2,
				}) / PIXELS_PER_FEET
			).toFixed(1)}'`;
		}

		const { x1, y1, x2, y2 } = object.calcLinePoints();
		return `${(
			distance({ x: x1, y: y1 })({
				x: x2,
				y: y2,
			}) / PIXELS_PER_FEET
		).toFixed(1)}'`;
	}

	const availableSizes = getAvailableSizes(object.subtype as Shape | undefined);

	const sizes = availableSizes
		.map((availableSize) => {
			const size = pixelsToFeet(
				availableSize === "depth"
					? getScaledHeight(object)
					: getScaledWidth(object)
			);

			if (size) {
				return size;
			}

			return undefined;
		})
		.filter((v) => v !== undefined) as readonly number[];

	return `${sizes.map((size) => `${size.toFixed(1)}'`).join(" x ")}`;
};

/**
 * Find the label from the Ecogarden Object's
 */
export const findLabel =
	(objects: readonly EcogardenObject[]) =>
	(id: string | undefined): string => {
		if (!id) {
			return "";
		}

		const object_ = findObject(objects)(id);

		if (!object_ || !object_.label) {
			return "";
		}

		return object_.label;
	};

/**
 * Find the size of an object in a group of objects
 */
export const findSizeFrom =
	(objects: readonly EcogardenObject[]) =>
	(id: string | undefined): number | undefined => {
		return getScaledWidth(findObject(objects)(id));
	};

/**
 * Find all the objects on a layer
 */
export const getObjectsOnLayer =
	(layer: Layer) =>
	(objects: readonly EcogardenObject[]): readonly EcogardenObject[] => {
		return objects.filter(
			(object) =>
				"subtype" in object &&
				object.subtype &&
				findShapeLayer(object.subtype) === layer
		);
	};

/**
 * Find the relevant size based on the subtype and type or return undefined.
 */
const findSize =
	(subtype: Shape | undefined) =>
	(type: "width" | "depth" | "size"): number | undefined => {
		const shapeDetail = findShapeDetail(subtype);

		if (!shapeDetail) {
			return undefined;
		}

		return shapeDetail[type] ?? shapeDetail?.size;
	};

export const sizeToScale =
	<S extends Shape | undefined>(subtype: S) =>
	(type: "width" | "depth" | "size") =>
	(sizeInPixels: number): number | undefined => {
		const defaultSize = findSize(subtype)(type);

		if (!defaultSize) {
			return undefined;
		}

		return sizeInPixels / defaultSize;
	};

export const defaultSizeToScale =
	(defaultSize: number) =>
	(sizeInPixels: number): number => {
		return sizeInPixels / defaultSize;
	};

/**
 * Convert size (in pixels) to scale based on the default size (in pixels) of the shape
 */
export const sizeInPixelsToScale =
	(_type: string | undefined, subtype: Shape | string | undefined) =>
	(sizeInPixels: number): number => {
		const defaultSize = findDefaultPixelSize(subtype);

		if (!defaultSize) {
			return 1;
		}

		return sizeInPixels / defaultSize;
	};
